<template>
  <modal-overlay @close="this.$emit('close')">
    <headline class="text-sm">
      Good for your wallet.<br />
      Good for the planet.
    </headline>
    <p :class="$style.title">
      <!--Future pays you for the CO₂ emissions you save when you electrify your
      ride.-->
      You just earned 2,500 ClimatePoints ($25).<br />
      Future rewards you for everyday choices to slash your carbon footprint -
      like electric driving.
    </p>
    <img
      :src="require('@/assets/images/moneyIcon.png')"
      width="150"
      :class="$style.moneyIcon"
      alt="money-icon"
    />
    <div v-if="!loading" :class="$style.organizer">
      <p :class="$style.subTitle">
        <!--Add your phone number below to turn those carbon savings into cash!-->
        Add your phone number below to claim your points.
      </p>

      <div :class="$style.fieldContainer">
        <p :class="$style.textError" v-if="error">
          {{ error }}
        </p>
        <p :class="$style.textError" v-if="!phoneNumberValid">
          Please enter a valid US phone number
        </p>
        <input
          v-model="virtualPhone"
          v-maska="'(###) ###-####'"
          type="text"
          inputmode="numeric"
          :class="
            phoneNumberValid ? $style.field : [$style.field, $style.fieldError]
          "
          placeholder="Enter your phone number"
        />
      </div>
      <p :class="$style.disclaimer">
        By clicking below, I agree to receive text messages from Future. Message
        and data rates may apply.
      </p>
      <button :class="$style.buttonDark" @click="submit">Submit</button>
      <a href="https://future.green" :class="$style.buttonGhost">
        <!--@click="$emit('close')">-->
        No Thanks
      </a>
    </div>
    <div v-else>
      <p :class="$style.subTitle">Loading...</p>
    </div>
  </modal-overlay>
</template>

<script>
import { throttle } from "lodash";
import Headline from "@/components/headline";
import ModalOverlay from "@/components/modal-overlay.vue";

export default {
  name: "modal-phone",
  components: { ModalOverlay, Headline },
  emits: ["next", "close"],
  data() {
    return {
      loading: false,
      phoneInitialized: false,
      virtualPhone: "",
      actualPhone: "",
      error: null,
    };
  },
  watch: {
    virtualPhone(n) {
      this.phoneInitialized = true;
      this.$nextTick(() => {
        this.actualPhone = n
          .replace("-", "")
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "");
      });
    },
    actualPhone(n) {
      this.error = null;
    },
  },
  computed: {
    phoneNumberValid() {
      if (this.phoneInitialized)
        return !(this.actualPhone.length > 10 || this.actualPhone.length < 9);
      return true;
    },
  },
  methods: {
    submit: throttle(function () {
      this.phoneInitialized = true;
      if (this.phoneNumberValid) {
        this.sendSMS();
      }
    }, 5000),
    sendSMS() {
      this.loading = true;
      this.$http({
        //url: "https://future-ev-switch-8783.twil.io/sms-ios",
        url: "/sms/ev-calculator",
        params: {
          //number: "+491786936737",
          number: `+1${this.actualPhone}`,
        },
      })
        .then(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.$emit("next");
          });
        })
        .catch((err) => {
          this.loading = false;
          this.error = err?.response?.data ?? "Please try a different number.";
        });
    },
  },
};
</script>

<style lang="scss" module>
.organizer {
  @apply flex flex-col gap-y-2;
}

.title {
  @media screen and (max-height: 400px) {
    display: none;
  }
  font-size: 1.1rem;
}

.subTitle {
  @apply text-lg;
  font-size: 1.1rem;
}

.disclaimer {
  @apply text-xs text-left;
}

.moneyIcon {
  @apply mx-auto;
  @media screen and (max-height: 400px) {
    display: none;
  }
}

.iconFloatRight {
  @apply self-end cursor-pointer;
}

.buttonGhost {
  @apply btn btn-ghost text-secondary normal-case rounded-full;
  color: #d5e67e;
}

.buttonDark {
  @apply btn btn-ghost text-primary normal-case rounded-full;
  background-color: #d5e67e !important;

  &:hover {
    @apply btn btn-ghost text-secondary normal-case rounded-full;
    background-color: #becc74 !important;
    color: #3c4606 !important;
  }
}

.fieldContainer {
  @apply flex flex-col space-y-5;
}

.field {
  @apply border-2 px-4 bg-secondary flex-none;
  height: 60px;
  border-color: rgba(0, 0, 0, 0.74);
  color: #151514 !important;

  &::placeholder {
    @apply text-secondary-focus;
  }

  &:focus {
    @apply outline-0 shadow-xl;
  }
}

.fieldError {
  @apply border-red-700;
}

.textError {
  @apply text-red-600 text-xs text-left -mb-4;
}
</style>
