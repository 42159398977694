<template>
  <div :class="$style.box">
    <slot />
  </div>
</template>

<script>
export default {
  name: "container",
};
</script>

<style lang="scss" module>
.box {
  @apply p-6 mt-3 mx-auto flex flex-col text-center gap-y-4;
  margin-top: 10vh;

  @media screen and (max-height: 630px) {
    background-color: rgba(236, 223, 201, 0.9);
    @apply rounded-xl shadow-xl;
    @apply gap-y-3 mt-10;
  }

  //background: rgba(256, 256, 256, 0.2);
  max-width: 90vw;
  height: auto;
  min-height: 50vh;

  @screen sm {
    max-width: 450px;
  }
}
</style>
