<template>
  <!-- Web -->
  <a
    href="https://getfuturecard.com/ev-calculator"
    target="_blank"
    :class="$style.box"
    v-if="display === 'QR'"
  >
    <p>Get the Future app now!</p>
    <p :class="$style.instructions">Scan or click to get started.</p>
    <QRCodeVue3
      :class="$style.center"
      :width="180"
      :height="180"
      value="https://getfuturecard.com/ev-calculator"
      :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
      :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
      :dotsOptions="{
        color: '#050505',
        gradient: {
          type: 'linear',
          rotation: 0,
          colorStops: [
            { offset: 0, color: '#223f09' },
            { offset: 1, color: '#0e1e01' },
          ],
        },
      }"
      :backgroundOptions="{ color: '#ffffff00' }"
      :cornersSquareOptions="{ type: 'square', color: '#000000' }"
      :cornersDotOptions="{ type: undefined, color: '#000000' }"
      fileExt="png"
      :downloadOptions="{ name: 'vqr', extension: 'png' }"
    />
  </a>
</template>

<script>
import QRCodeVue3 from "qrcode-vue3";
// eslint-disable-next-line no-undef
const UA = require("ua-parser-js");
// eslint-disable-next-line no-unused-vars
let deviceInfo = new UA();

export default {
  name: "get-the-app",
  components: { QRCodeVue3 },
  data() {
    return {
      os: {},
      display: "QR",
    };
  },
  async mounted() {
    this.os = deviceInfo ?? "";
  },
};
</script>

<style lang="scss" module>
.instructions {
  @apply text-xs;
}

.box {
  @apply p-2 border border-black rounded-md text-lg bg-secondary flex flex-col py-2;
}

.center {
  @apply mx-auto mb-2;
  img {
    @apply mx-auto;
  }
}
</style>
