import { createRouter, createWebHistory } from "vue-router";

import Start from "@/views/Start";


const routes = [
  {
    path: "/",
    name: "Entry",
    component: Start,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Any",
    component: Start,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
