<template>
  <div :class="$style.main">
    <logo-header />
    <container>
      <img
        :src="require('@/assets/images/crazyPhonePortalIcon.png')"
        width="180"
        :class="$style.crazyPhone"
        alt="phone-icon"
      />
      <headline>Thanks!</headline>
      <headline>Keep an eye out for a text message from Future</headline>
      <p :class="[$style.groundText, $style.textSafe]">
        In the meantime, learn more about how an electric vehicle can help you help the
        planet.
      </p>
      <a href="https://future.green/" target="_blank">
        <button :class="$style.buttonDark">
          Go to Future.Green to Save More
        </button>
      </a>
      <get-the-app />
    </container>
  </div>
</template>

<script>
import Container from "@/components/container";
import Headline from "@/components/headline";
import GetTheApp from "@/components/get-the-app.vue";
import LogoHeader from "@/components/logo-header.vue";

export default {
  name: "Thanks",
  components: { LogoHeader, GetTheApp, Headline, Container },
  props: ["os"],
};
</script>

<style lang="scss" module>
.main {
  @apply overflow-x-hidden overflow-y-scroll h-screen w-screen z-10;
  .header {
    @apply flex justify-between mx-auto mt-5;
    max-width: 90vw;
  }
}

.logo {
  @apply flex-none drop-shadow-none select-none pointer-events-none;
}

.crazyPhone {
  @apply mx-auto;
}

.disclaimer {
  @apply flex space-x-2 text-xs mx-auto cursor-pointer;
}

.groundText {
  @apply text-lg;
}

.buttonDark {
  @apply btn btn-primary rounded-full normal-case w-full;
}

.textSafe {
  @apply rounded-xl;
}
</style>
