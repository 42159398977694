<template>
  <div :class="$style.main">
    <logos-header />
    <container>
      <!--<headline>Get rewards for electrifying your ride</headline>-->
      <!--<p>
        Let us know what car you are driving now, and we’ll estimate how many
        tons of carbon you save a year and how much you’d earn in rewards for
        helping the planet.
      </p>-->

      <!--<headline>What you drive matters.</headline>
      <p>
        Let us know what car you are driving now, and we’ll estimate how many
        tons of carbon you save a year by going electric.
      </p>-->
      <headline>Save a ton by going electric</headline>
      <p>
        Enter the license plate info for the car you are driving now to see how much you can save per year by going electric!
      </p>

      <p :class="$style.textError" v-if="this.error">
        {{ error }}
      </p>
      <div :class="$style.fieldContainer">
        <p :class="$style.textError" v-if="this.fieldsValidated.license === 1">
          Please enter your license plate
        </p>
        <input
          :maxlength="8"
          v-text="8 - selected.license.length"
          v-model="selected.license"
          type="text"
          :class="getStyleForInput(this.fieldsValidated.license)"
          placeholder="Enter your car's license plate"
        />
      </div>
      <div :class="$style.fieldContainer">
        <p :class="$style.textError" v-if="this.fieldsValidated.state === 1">
          Please select your state
        </p>
        <select
          :class="getStyleForInput(this.fieldsValidated.state)"
          v-model="selected.state"
        >
          <option :value="null" disabled>Select your state</option>
          <option :value="key" v-for="(state, key) in states">
            {{ state }}
          </option>
        </select>
      </div>
      <button :class="$style.buttonDark" @click="seeMyResults">
        See My Results
      </button>
    </container>
  </div>
</template>

<script>
import Container from "@/components/container";
import Headline from "@/components/headline";
import LogosHeader from "@/components/logo-header.vue";

// eslint-disable-next-line no-undef
let states = require("@/assets/states_hash.json");

export default {
  name: "EnterLicensePlate",
  props: ["error", "savedLicense"],
  components: {LogosHeader,  Headline, Container },
  emits: ["license"],
  data() {
    return {
      states: [],
      hasBeenChanged: {
        state: false,
        license: false,
      },
      selected: {
        state: null,
        license: "",
      },
    };
  },
  computed: {
    selectedC() {
      return Object.assign({}, this.selected);
    },
    fieldsValidated() {
      // 0 = not inited, 1 = inited but has error, 2 = inited with success
      let state = 0;
      let license = 0;
      if (this.hasBeenChanged.state) {
        if (this.selected.state === null) {
          state = 1;
        } else {
          state = 2;
        }
      }

      if (this.hasBeenChanged.license) {
        if (this.selected.license === "") {
          license = 1;
        } else {
          license = 2;
        }
      }

      return {
        license: license,
        state: state,
      };
    },
  },
  methods: {
    seeMyResults() {
      this.hasBeenChanged.license = true;
      this.hasBeenChanged.state = true;
      if (
        this.fieldsValidated.license === 2 &&
        this.fieldsValidated.state === 2
      ) {
        this.$emit("license", this.selected);
      }
    },
    getStyleForInput(state) {
      if (state === 0) {
        return this.$style.field;
      }
      if (state === 1) {
        return [this.$style.field, this.$style.fieldError];
      }
      if (state === 2) {
        return this.$style.field;
      }
    },
  },
  watch: {
    selectedC: {
      deep: true,
      handler: function (n, o) {
        if (n.license !== o.license) {
          this.hasBeenChanged.license = true;
        }
        if (n.state !== o.state) {
          this.hasBeenChanged.state = true;
        }
      },
    },
  },
  mounted() {
    this.states = states;
    console.log(this.savedLicense);
    if (this.savedLicense?.state) {
      console.log("SETTING STATE");
      this.selected.state = this.savedLicense?.state;
    }
    if (this.savedLicense?.license) {
      console.log("SETTING LICENSE");
      this.selected.license = this.savedLicense?.license;
    }
  },
};
</script>

<style lang="scss" module>
.main {
  @apply overflow-x-hidden overflow-y-scroll h-screen w-screen z-10;
}

.buttonDark {
  @apply btn btn-primary rounded-full normal-case shadow-md;
}

.fieldContainer {
  @apply flex flex-col space-y-5;
}

.field {
  @apply border-2 px-3 bg-secondary flex-none rounded-none;
  height: 60px;
  border-color: rgba(0, 0, 0, 0.74);

  &::placeholder {
    @apply text-secondary-focus;
  }

  &:focus {
    @apply outline-0 shadow-xl;
  }
}

.fieldError {
  @apply border-red-700;
}

.textError {
  @apply text-red-700 text-xs text-left;
}
</style>
