<template>
  <img
    width="100vw"
    height="100vh"
    :src="require('@/assets/images/introBackground.png')"
    :class="[$style.background, $style.greenBackground]"
    v-if="page === 1"
    alt="background"
  />
  <EnterLicensePlate
    v-if="page === 1"
    @license="getPersonalCar"
    :error="error"
    :saved-license="savedLicense"
  />
  <img
    width="100vw"
    height="100vh"
    :src="require('@/assets/images/loadingBackground.png')"
    :class="[$style.background, $style.greenBackground]"
    v-if="page === 2"
    alt="background"
  />
  <Loading v-if="page === 2" />
  <img
    width="100vw"
    height="100vh"
    :src="require('@/assets/images/resultsBackground.png')"
    :class="[$style.background, $style.yellowBackground]"
    v-if="page === 3"
    alt="background"
  />
  <Results
    v-if="page === 3"
    :computed-rewards="computedRewards"
    :show-savings="showSavings"
    @next="goToThanksPage"
  />
  <img
    width="100vw"
    height="100vh"
    :src="require('@/assets/images/thanksBackground.png')"
    :class="[$style.background, $style.yellowBackground]"
    alt="background"
    v-if="page === 4"
  />
  <Thanks v-if="page === 4" />
</template>

<script>
import EnterLicensePlate from "@/views/EnterLicensePlate";
import Loading from "@/views/Loading";
import Results from "@/views/Results";
import Thanks from "@/views/Thanks";

// eslint-disable-next-line no-undef
const loadImage = require("blueimp-load-image");

const netErrMsg = "Network error, please try again.";

export default {
  name: "Start",
  components: { Thanks, Results, Loading, EnterLicensePlate },
  data() {
    return {
      page: 1,
      electricCar: {},
      error: "",
      showSavings: true,
      savedLicense: {
        state: null,
        license: "",
      },
      computedRewards: {},
      /*computedRewards: {
        impactInTons: 5,
        futureRewards: 420,
        gasolineSavings: 3260,
        oldCar: {
          year: 2019,
          make: "CHEVROLET",
          model: "Express",
        },
      },*/
    };
  },
  async mounted() {
    /**
     * Load bigger images
     */
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      loadImage(require("../assets/images/loadingBackground.png"));
      // eslint-disable-next-line no-undef
      loadImage(require("../assets/images/resultsBackground.png"));
      // eslint-disable-next-line no-undef
      loadImage(require("../assets/images/thanksBackground.png"));
      // eslint-disable-next-line no-undef
      loadImage(require("../assets/images/appiStore.png"));
      // eslint-disable-next-line no-undef
      loadImage(require("../assets/images/spinner.svg"));
      // eslint-disable-next-line no-undef
      loadImage(require("../assets/images/ID.4.jpg"));
    }, 6000);

    await this.getElectricCar();
  },
  methods: {
    async getElectricCar() {
      await this.$http({
        url: "/search/electric",
        params: {
          year: 2023,
          make: "Volkswagen",
          model: "ID.4 Pro",
          option: "Automatic (A1)",
        },
      })
        .then((res) => {
          this.electricCar = res.data[0];
          console.log("ELECTRIC CAR LOADED", this.electricCar);
        })
        .catch((err) => {
          console.log(err?.response);
        });
    },
    async getPersonalCar(license) {
      this.page++;
      this.savedLicense = license;
      await this.$http({
        url: "/search/personal",
        method: "GET",
        params: {
          license: [license.state, license.license],
        },
      })
        .then((res) => {
          console.log(res.data);
          this.personalCar = res.data;
          console.log("PERSONAL CAR LOADED", this.personalCar);
          // Now that we have the personal car info, we can compute rewards
          this.getResults(this.electricCar, this.personalCar);
        })
        .catch((err) => {
          this.page--;
          console.log(err?.response);
          this.error = err?.response?.data?.issue ?? netErrMsg;
        });
    },
    async getResults() {
      await this.$http({
        url: "/calculate/rewards",
        method: "POST",
        data: {
          personalCar: this.personalCar,
          electricCar: this.electricCar,
        },
      })
        .then((res) => {
          this.computedRewards = res.data;
        })
        .catch((err) => {
          this.error = err?.response?.data?.issue ?? netErrMsg;
          console.log(this.error);
          setTimeout(() => {
            this.page--;
          }, 1000);
        });
      // Delay
      setTimeout(() => {
        this.page++;
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        });
      }, 2000);
    },
    goToThanksPage() {
      this.page++;
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },
  },
};
</script>

<style lang="scss" module>
.background {
  @apply absolute top-0 left-0 inline-block overflow-x-hidden overflow-y-hidden;
  min-height: 100vh;
  min-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.yellowBackground {
  background-color: #eee4ce;
}

.greenBackground {
  background-color: #bfc6aa;
}
</style>

<style>
body {
  height: 100vh;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}
</style>
